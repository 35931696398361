import React from "react";
import _ from "lodash";
import Expertise from '../components/Expertise'
import Icon from '../components/Icon';
import speed from '../media/icons/speed.svg';
import IllustrationData from '../media/illustration/Analytics.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'projet-data-ia',
      title: "Accélérer les projets data et IA en saté",
      description: "Une idée de projet pour améliorer la qualité de vie des patients ou des soignants ? Akimed possède tous les outils pour développer ce projet.",
      content:
        <div class="columns">
            <div class="column section-panel is-8">
                <div class="content is-medium">
                <p>Développer une technologie dans la santé, c’est faire face à une <strong>pression constante</strong> pour respecter la cadence de sortie de nouvelles versions tout en assurant un <strong>équilibre</strong> des coûts, des ressources et de la maintenance. Le marché évoluant rapidement, les organisations doivent continuellement innover pour suivre le rythme.</p>
                <p>Akimed aide les entreprises qui développent des technologies dans la santé à relever ces défis pour maximiser l’impact positif que peuvent avoir ces technologies dans la santé. Nous fournissons les solutions digitales dont vous avez besoin aujourd’hui pour continuer à vous développer.</p>
                <p>Un partenariat avec Akimed, c’est aussi&nbsp;:</p>
                <ul>
                    <li>Un accès à une expérience approfondie du développement, des tests et du support de produits SaaS en santé&nbsp;;</li>
                    <li>L’accès à des équipes de <strong>développement full-stack</strong>&nbsp;;</li>
                    <li>Expertise pour travailler sur des données de santé conformes à RGPD, HIPAA ou 21 CFR Part 11.</li>
                </ul>
                </div>
            </div>
            <div class="column section-panel is-3 is-offset-1">
                <figure class="image">
                <Icon src={IllustrationData} />
                </figure>
            </div>
        </div>
    };
  }

  render() {
    return (
        <Expertise
          content={this.state.content}
          activePage={this.state.page} 
          title={this.state.title}
          description={this.state.description}
        />
    );
  }
}
